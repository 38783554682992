<template>
	<div class="theall" v-wechat-title="$route.meta.title">
		<div class="topbackclass"></div>
		<div class="centerclass">
			<div class="centerchild">
				<div class="logoclass">
					<img src="../../assets/login_logo.png" alt="" />
				</div>
				<div class="inputmsgclass">
					<input v-model="phone" class="shoujihao" type="number" name="" id="" placeholder="请输入手机号" />
				</div>
				<div class="inputmsgclass inputmsgclass2">
					<input v-model="code" class="shoujihao" type="number" name="" id="" placeholder="请输入验证码" />
					<div :style="
              getcode == '获取验证码' ? '' : 'width:2.3rem;color:#909399;'
            " @click="cligetcode">
						{{ getcode == "获取验证码" ? getcode : `${getcode}s后可重新重发` }}
					</div>
				</div>
				<div class="inputmsgclass">
					<input v-model="reg_company" class="shoujihao" type="text" name="" id="" placeholder="请输入公司名称" />
				</div>
				<div class="box bt">
					<van-checkbox checked-color="#FBA110" v-model="checked">我已阅读并同意<a @click.stop=""
							href="/serviceprotocol">《萝卜猎手服务协议》</a>
					</van-checkbox>
				</div>
				<div ontouchstart="" @click="regis" :class="cantologin ? 'loginclass2' : 'loginclass'">
					注册
				</div>
				<div @click="$router.push('/login')" class="backlogin">返回登录</div>
			</div>
		</div>
		<!-- <div class="xieyi">
      登录注册即代表同意<span @click.stop="$router.push('/serviceprotocol')"
        >《萝卜猎手服务协议》</span
      >
    </div> -->

		<sharepage></sharepage>
		<myalert @okmeth="showalert = false" @nometh="showalert = false" :show="showalert">
			<div class="thejian">立即注册，即可免费获取该简历。</div>
		</myalert>
		<myalert @okmeth="okmeth2" @nometh="showalert2 = false" :show="showalert2">
			<div class="thejian">
				注册成功，该简历已收藏，请<span style="font-weight: bold">联系客服</span>
				免费获取余额。
			</div>
		</myalert>
	</div>
</template>
<script>
	// $router.push('/registersuccess')

	var intervalID;
	import {
		Dialog
	} from "vant";
	import sharepage from "../../views/sharepage/index";
	import loginlocal from "../../../util/loginlocal";
	export default {
		data() {
			return {
				radio: "",
				phone: "", //手机号
				code: "", //验证码
				incode: "", //邀请码
				getcode: "获取验证码", //获取验证码
				checked: false,
				sub_code: "", //简历公海分享code
				urlsharees: "", //简历公海路径
				showalert: false,
				showalert2: false,
				share_phone: "", //分享手机号
				favoid: "", //简历id
				reg_company: "", //企业名称
				cantologin: false,
				intervalID: "-1",
			};
		},
		components: {
			sharepage,
		},
		beforeCreate() {},
		watch: {
			phone(newmsg) {
				if (/^1[3-9]\d{9}$/.test(newmsg)) {
					this.cantologin = true;
				} else {
					this.cantologin = false;
				}
			},
			reg_company(msg, msg2) {
				var re = /[^\u4e00-\u9fa5]/;
				if (re.test(msg.replace(/\（|\）/g, ""))) {
					this.reg_company = msg2;
					this.$toast("只能输入中文和括号（）");
				}
			},
		},
		created() {
			this.tosharemeth();
		},
		watch: {
			phone(newmsg) {
				if (/^1[3-9]\d{9}$/.test(newmsg)) {
					this.cantologin = true;
				} else {
					this.cantologin = false;
				}
			},
			code(oldmsg) {
				if ((oldmsg + "").length > 6) {
					this.code = (oldmsg + "").substr(0, 6);
				}
			},
		},
		destroyed() {
			sessionStorage.removeItem("sharemsg");
		},
		mounted() {
			if (this.$route.query.login_submsg) {
				if (this.$route.query.share_phone) {
					this.share_phone = this.$route.query.share_phone;
				}

				try {
					//获取分享简历id
					this.favoid = atob(this.$route.query.id);
				} catch (e) {}

				if (this.$route.query.sub_code) {
					this.sub_code = this.$route.query.sub_code;
				}
				this.urlsharees = this.$route.query.urlsharees;
				if (this.urlsharees == "/sub") {
					this.showalert = true;
				}
			} else {
				if (sessionStorage.getItem("sharePhoneToRegister")) {
					this.share_phone = sessionStorage.getItem("sharePhoneToRegister");
				}
			}
			if (localStorage.getItem("invitecode")) {
				this.incode = localStorage.getItem("invitecode");
			}
			if (
				this.$route.query.invitecode != undefined &&
				this.$route.query.invitecode != ""
			) {
				this.incode = this.$route.query.invitecode;
			}
		},
		methods: {
			//收藏简历
			favorites() {
				this.$http
					.post("/firm/v1/Resume/collect", {
						reqType: "resume",
						report_id: this.favoid,
					})
					.then((res) => {});
			},
			tosharemeth() {
				let obj = JSON.stringify({
					sharetitle: "萝卜猎手背景调查",
					sharedesc: "一秒钟还原履历真实性，即刻体验~",
					isshare: "1",
					share_phone: localStorage.getItem("phone"),
				});
				localStorage.setItem("shareobj", obj);
			},
			/**
			 * 登录
			 */
			tologin(phone, pass) {
				this.$http
					.post("/firm/v1/login/login", {
						reqType: "login",
						mobile: phone,
						password: pass,
						login_type: 1
					})
					.then((res) => {
						const arr = JSON.parse(res.data);

						if (arr.code === "6007" || arr.code === "6006") {
							this.$toast.fail("手机号或密码错误");
							return;
						}
						this.$toast.success("登录成功");

						loginlocal(arr.data);

						if (this.urlsharees) {
							this.showalert2 = true;
						} else {
							setTimeout(() => {
								this.$router.push("/enterprise");
							}, 500);
						}
					})
					.catch((err) => {});
			},
			okmeth2() {
				this.showalert2 = false;
				//收藏简历
				this.favorites();
				this.$router.push("/enterprise");
			},
			/**
			 * 计时器
			 */
			timeing() {
				this.getcode = 60;
				this.intervalID = setInterval(() => {
					this.getcode--;

					if (this.getcode < 1) {
						clearInterval(this.intervalID);
						this.getcode = "获取验证码";
					}
				}, 1000);
			},

			/**
			 * 停止计时器
			 */
			destroyed() {
				clearInterval(this.intervalID);
			},

			/**
			 * 获取验证码
			 */
			cligetcode() {
				if (this.getcode != "获取验证码") {
					return;
				}
				if (this.phone == "") {
					Dialog({
						message: "请输入正确手机号"
					});
					return;
				} else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
					Dialog({
						message: "请输入正确手机号"
					});
					return;
				}

				this.timeing();

				//获取验证码
				this.sendcode();
			},

			/**
			 * 发送验证码
			 */
			sendcode() {
				this.$http
					.post("/firm/v1/login/valid_send", {
						reqType: "login",
						mobile: this.phone,
						source: "regist",
						login_type: 1
					})
					.then((res) => {
						this.$toast(JSON.parse(res.data).msg);
					})
					.catch((res) => {});
			},

			/**
			 * 提交
			 */
			regis() {
				if (!this.cantologin) {
					return;
				}
				if (this.phone == "") {
					Dialog({
						message: "请输入手机号"
					});
					return;
				}
				if (!/^1[3-9]\d{9}$/.test(this.phone)) {
					Dialog({
						message: "请输入正确手机号"
					});
					return;
				}
				if (this.code == "") {
					Dialog({
						message: "请输入验证码"
					});
					return;
				}
				if (this.checked != true) {
					Dialog({
						message: "请勾选协议"
					});
					return;
				}
				this.$toast.loading();
				this.$http
					.post("/firm/v1/regist/add", {
						reqType: "regist",
						mobile: this.phone, // 手机号
						mobile_code: this.code, //验证码
						reg_company: this.reg_company, //公司名称
						bind_code: this.share_phone ? this.share_phone : this.incode, //邀请码
						is_identity: 1,
						login_type: 1
					})
					.then((res) => {
						let themsg = JSON.parse(res.data);

						//自动登录
						this.tologin(this.phone, themsg.data.password);

						this.$toast(JSON.parse(res.data).msg);
						// this.$router.push("/registersuccess");
					})
					.catch((res) => {
						this.$toast(JSON.parse(res.data).msg);
					});
			},
		},
	};
</script>
<style scoped lang="scss">
	.backlogin {
		font-size: 0.28rem;
		font-weight: 500;
		color: rgba(69, 113, 208, 0.9);
		line-height: 0.48rem;
		text-align: center;
		margin-top: 0.28rem;
	}

	.loginmethclass {
		font-size: 0.28rem;
		font-weight: 500;
		color: #606266;
		line-height: 0.48rem;
		text-align: center;
		margin-top: 0.28rem;
	}

	.loginclass {
		font-size: 0.32rem;
		font-weight: 500;
		color: #ffffff;
		line-height: 0.44rem;
		text-shadow: 0px 0px 0px rgba(249, 137, 53, 0.59);
		padding: 0.26rem 0;
		text-align: center;
		background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
		box-shadow: 0rem 0.08rem 0.16rem 0rem rgba(249, 137, 53, 0.59);
		border-radius: 0.48rem;
		opacity: 0.6;
		margin-top: 0.5rem;
	}

	.loginclass2 {
		text-align: center;
		font-size: 0.32rem;
		font-weight: 500;
		color: #ffffff;
		line-height: 0.44rem;
		text-shadow: 0px 0px 0px #ffceaa;
		padding: 0.26rem 0;
		background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
		box-shadow: 0rem 0.08rem 0.16rem 0rem #ffceaa;
		border-radius: 0.48rem;
		margin-top: 0.5rem;
	}

	.loginclass2:active {
		transform: scale(0.9);
	}

	.fangshiclass {
		font-size: 0.28rem;
		font-weight: 400;
		color: rgba(69, 113, 208, 0.9);
		line-height: 0.48rem;
		padding-top: 0.2rem;
		padding-left: 0.08rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.inputmsgclass2 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.inputmsgclass2 div {
		width: 1.6rem;
		font-size: 0.28rem;
		font-weight: 500;
		color: rgba(254, 89, 3, 0.75);
		line-height: 0.48rem;
	}

	.inputmsgclass2 input {
		flex: 1;
	}

	.inputmsgclass {
		padding: 0.28rem 0;
		border-bottom-width: 1px;
		border-bottom-color: #efefef;
		border-bottom-style: solid;
	}

	.shoujihao {
		width: 100%;
		font-size: 0.32rem;
		font-weight: 400;
		color: #303133;
		line-height: 0.48rem;
		border: none;
	}

	.logoclass {
		text-align: center;
		margin-bottom: 0.58rem;
	}

	.logoclass img {
		width: 4.34rem;
		margin: 0;
	}

	.xieyi {
		font-size: 0.28rem;
		font-weight: 400;
		color: #909399;
		line-height: 0.48rem;
		text-align: center;
		margin-top: 1vh;
	}

	.xieyi span {
		color: #fe5903;
	}

	.centerclass {
		background-color: #ffffff;
		height: 70vh;
		margin: 0 0.24rem;
		border-radius: 0.3rem;
		margin-top: -15vh;
		padding: 5vh 7vw;
	}

	.topbackclass {
		height: 30vh;
		background-image: url("../../assets/login_back.png");
		background-size: 100% 100%;
	}

	.thejian {
		font-size: 0.35rem;
		padding: 0.3rem;
		text-align: center;
	}

	.visi {
		display: none;
	}

	#app {
		height: auto;
	}

	.topnomsgthe {
		height: 0.2rem;
	}

	.ggg {
		height: 0.5rem;
	}

	.falv {
		text-align: center;
		color: #f55613;
		margin: 0 0.5rem;
		background-color: #fdf5f3;
		padding: 0.1rem 0.2rem;
		border-radius: 0.2rem;
		margin-top: 0.3rem;
	}

	.theall {
		background-color: #f6f6f7;
		min-height: 100%;
	}

	/deep/ a {
		color: #f55613;
	}

	.theall>p {
		text-align: center;
		color: #ef811a;
		font-size: 0.34rem;
		font-weight: 500;
	}

	.userImage {
		text-align: center;
	}

	.box {
		display: flex;
		line-height: 0.52rem;
	}

	.box .box1 {
		margin-left: 0.15rem;
		width: 4.42rem;
		height: 0.52rem;
		font-size: 0.26rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 0.52rem;
	}

	.box p {
		margin-left: 0.7rem;
		width: 1.04rem;
		height: 0.52rem;
		font-size: 0.26rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #f55613;
		line-height: 0.52rem;
	}

	img {
		width: 2.47rem;
		margin-top: 0.6rem;
	}

	.alldata {
		padding-top: 0.99rem;
		margin: 0 0.48rem;
	}

	.data {
		border-bottom-color: #ebeef5;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		font-size: 0.32rem;
		padding-top: 0.26rem;
		padding-bottom: 0.34rem;
	}

	.fx {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.fx>input {
		width: 3.5rem;
		padding: 0;
	}

	.fx>p {
		line-height: 1px;
		color: #ef811a;
	}

	::placeholder {
		color: #c9ced6;
	}

	.fx .secla {
		color: #c9ced6;
	}

	.data>input {
		border: none;
	}

	.loginP {
		color: #ef821a;
		font-size: 0.3rem;
		margin-top: 0.24rem;
	}

	.lp {
		border: none;
		display: flex;
		justify-content: space-between;
	}

	button {
		width: 90%;
		color: #ffffff;
		background: linear-gradient(to right, #f55613, #fc9e46);
		margin: 0 5%;
		border: none;
		border-radius: 30px;
		font-size: 20px;
		padding: 0.2rem;
	}

	.protocol {
		text-align: center;
		font-size: 13px;
		margin-top: 0.3rem;
		color: #c9ced6;
	}

	.bt {
		margin-top: 0.36rem;
	}

	.xieyi {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.26rem;
		color: #c9ced6;
		margin-top: 0.2rem;
	}
</style>
